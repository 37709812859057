export default {
    "name": "homeQuery",
    "kind": "HoudiniQuery",
    "hash": "433d754675110f69ecb1cbb769ea3c19cf1a53de540310f3a28e4a43c8ed559f",

    "raw": `query homeQuery {
  bobAwards: awardCollection(
    limit: 20
    order: year_DESC
    where: {program: {sys: {id: "5r2rtiK5X2GymqrPX0liGR"}}}
  ) {
    total
    skip
    limit
    items {
      name
      year
      program {
        name
      }
      image {
        alt
        caption
        desktop
        mobile
      }
    }
  }
  bhlAwards: awardCollection(
    limit: 20
    order: year_DESC
    where: {program: {sys: {id: "4RaSEBBgBZN78CYrDRoymu"}}}
  ) {
    total
    skip
    limit
    items {
      name
      year
      program {
        name
      }
      image {
        alt
        caption
        desktop
        mobile
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "bobAwards": {
                "type": "AwardCollection",
                "keyRaw": "bobAwards(limit: 20, order: year_DESC, where: {program: {sys: {id: \"5r2rtiK5X2GymqrPX0liGR\"}}})",
                "nullable": true,

                "selection": {
                    "fields": {
                        "total": {
                            "type": "Int",
                            "keyRaw": "total",
                            "visible": true
                        },

                        "skip": {
                            "type": "Int",
                            "keyRaw": "skip",
                            "visible": true
                        },

                        "limit": {
                            "type": "Int",
                            "keyRaw": "limit",
                            "visible": true
                        },

                        "items": {
                            "type": "Award",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "year": {
                                        "type": "Int",
                                        "keyRaw": "year",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "program": {
                                        "type": "AwardProgram",
                                        "keyRaw": "program",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "image": {
                                        "type": "ContentImage",
                                        "keyRaw": "image",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "alt": {
                                                    "type": "String",
                                                    "keyRaw": "alt",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "caption": {
                                                    "type": "String",
                                                    "keyRaw": "caption",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "desktop": {
                                                    "type": "JSON",
                                                    "keyRaw": "desktop",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "mobile": {
                                                    "type": "JSON",
                                                    "keyRaw": "mobile",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "bhlAwards": {
                "type": "AwardCollection",
                "keyRaw": "bhlAwards(limit: 20, order: year_DESC, where: {program: {sys: {id: \"4RaSEBBgBZN78CYrDRoymu\"}}})",
                "nullable": true,

                "selection": {
                    "fields": {
                        "total": {
                            "type": "Int",
                            "keyRaw": "total",
                            "visible": true
                        },

                        "skip": {
                            "type": "Int",
                            "keyRaw": "skip",
                            "visible": true
                        },

                        "limit": {
                            "type": "Int",
                            "keyRaw": "limit",
                            "visible": true
                        },

                        "items": {
                            "type": "Award",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "year": {
                                        "type": "Int",
                                        "keyRaw": "year",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "program": {
                                        "type": "AwardProgram",
                                        "keyRaw": "program",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "image": {
                                        "type": "ContentImage",
                                        "keyRaw": "image",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "alt": {
                                                    "type": "String",
                                                    "keyRaw": "alt",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "caption": {
                                                    "type": "String",
                                                    "keyRaw": "caption",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "desktop": {
                                                    "type": "JSON",
                                                    "keyRaw": "desktop",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "mobile": {
                                                    "type": "JSON",
                                                    "keyRaw": "mobile",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=371112a76fc1eb36ff78dab192e28d2f08199ceafe5038046b989ac1c54e7e5b";