<script lang="ts">
	import { page } from '$app/stores';
	import { CtaButton } from '$lib/components/ctas';
	import { Coupon, ScheduleButton } from '$lib/sections/ctas';
	import { faScrewdriverWrench } from '@awesome.me/kit-065f90d5b1/icons/classic/regular';

	type HasItems<T> = T extends { items: infer U } ? U : never;

	let { routeInfo } = $page.data;
</script>

<section class="bg-no-repeat bg-cover bg-top aspect-[0.76/1] py-32" style="background-image:url('//res.cloudinary.com/thomasedison/image/upload/c_scale,f_auto,q_auto,w_1900/home/fancy-background')">
	<div class="relative md:px-16">
		<h2 class="font-headline font-semibold text-3xl md:text-6xl text-center text-tee-blue-default">Local Electricians You Can Trust</h2>

		<div class="relative rounded-[20px] mt-60 p-4 md:max-w-4xl mx-auto bg-white/50 z-10">
			<img loading="lazy" class="z-10 absolute -top-[234px] left-1/2 -translate-x-1/2" src="//res.cloudinary.com/thomasedison/image/upload/f_auto,q_auto/home/tee-technician-graphic" alt="" />
			<div class="bg-tee-blue-default rounded-[20px] z-0">
				<div class="flex flex-col place-items-center place-content-center px-8 py-16 sm:px-16 gap-12">
					<h3 class="font-headline text-white text-2xl text-center">Featured Electrical Services</h3>
					<ul class="list-disc text-white max-md:flex max-md:flex-col md:columns-2 ml-4 md:gap-x-16 space-y-2">
						{#each routeInfo.relatedServices.items as service}
							{#if service?.page}
								<li><a class="hover:underline" href={service.page.path}>{service.name}</a></li>
							{/if}
						{/each}
					</ul>
				</div>
			</div>
		</div>

		<div class="flex flex-col justify-center sm:flex-row items-center gap-6 my-4 pt-12 z-0">
			<ScheduleButton />
			<CtaButton theme="light" icon={faScrewdriverWrench} href="/services">See Additional Services</CtaButton>
		</div>
	</div>

	<div class="p-8 md:px-16 -mt-4">
		<Coupon />
	</div>
</section>
