<script>
	import { twMerge } from 'tailwind-merge';
	import { classes } from '$stores';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faPrint } from '@awesome.me/kit-065f90d5b1/icons/classic/regular';
	import { faScissors } from '@awesome.me/kit-065f90d5b1/icons/classic/light';
	import { CtaButton } from '$lib/components/ctas';

	let h2Classes = twMerge(classes.blocks.h3, 'lg:text-3xl text-white text-center');
</script>

<section class="flex flex-col md:max-w-4xl mx-auto gap-6">
	<h2 class={h2Classes}>Save on Electrical Services Today!</h2>
	<div class="flex place-items-center place-content-center py-24 bg-tee-blue-t90 rounded-lg border-2 border-dashed border-tee-blue-default relative md:aspect-[2.2/1]">
		<FontAwesomeIcon icon={faScissors} size="2x" rotation={270} class="absolute -left-4 top-1/2 -mt-4" />
		<div class="flex flex-col place-items-center place-content-center max-w-2xl md:gap-6 text-center">
			<h3 class="font-headline text-tee-orange-default text-3xl sm:text-5xl md:text-7xl leading-tight font-bold">Up to $100 Off</h3>
			<p class="font-headline text-2xl sm:text-3xl md:text-5xl font-semibold">Limited Time Offer!</p>
		</div>
	</div>
	<div class="mx-auto">
		<CtaButton theme="light" icon={faPrint} href="/coupon/100" target="_blank" rel="nofollow">Print Coupon</CtaButton>
	</div>
</section>
