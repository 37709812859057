<script lang="ts">
	import { twMerge } from 'tailwind-merge';
	import { classes } from '$stores';
	import { ScheduleButton } from '$lib/sections/ctas';
	import { ContentArea } from '$lib/sections/pages';
	import { DetailViewHero } from '$lib/sections/contentful';
	import { FeaturedServices } from '$lib/sections/home';
	import type { PageData } from './$houdini';
	import { Picture } from '$lib/components';

	export let data: PageData;

	const { homeQuery } = data;
	const { bobAwards, bhlAwards } = $homeQuery.data;

	let byLineClasses = {
		heading: twMerge(classes.blocks.h3, 'lg:text-3xl text-tee-red-default pt-0'),
		text: 'text-xl'
	};
	let bylines = [
		{
			heading: 'Excellence',
			text: 'Our skilled electricians provide high-quality services that stand the test of time.'
		},
		{
			heading: 'Timeliness',
			text: "Your time is precious. If we're late, we'll waive your dispatch fee."
		},
		{
			heading: 'Honesty',
			text: 'Transparent pricing means written quotes with no surprises.'
		}
	];
</script>

<DetailViewHero>
	<svelte:fragment slot="buttons">
		<ScheduleButton theme="light" />
	</svelte:fragment>
</DetailViewHero>
<ContentArea background="[background-image:linear-gradient(180deg,_#F2F6F9_4.28%,_rgba(255,_255,_255,_0.202772)_69.19%,_rgba(255,_255,_255,_0)_89.5%),_url(//res.cloudinary.com/thomasedison/image/upload/f_auto,q_auto/home/home-excellence-bg)] bg-cover bg-no-repeat">
	<h2 class={classes.blocks.intro}>We're Committed to Your Satisfaction</h2>
	<ul class="flex flex-col md:flex-row gap-4 text-center md:text-left">
		{#each bylines as byline}
			<li class="flex flex-1 flex-col shadow-md p-10 bg-white rounded-lg">
				<h3 class={byLineClasses.heading}>{byline.heading}</h3>
				<p class={byLineClasses.text}>{byline.text}</p>
			</li>
		{/each}
	</ul>
</ContentArea>

<ContentArea>
	<div>
		<h3 class={classes.blocks.h3}>The Electrical Help You Need, When You Need It</h3>
		<p class={classes.blocks.p}>When you need a 24-hour electrician, you can count on us to deliver prompt, professional service every time. Whether you have an old home in need of electrical rewiring, a garage in need of an EV charging station, or a burning smell in need of attention, the friendly folks at Thomas Edison have your back.</p>
	</div>
</ContentArea>

<FeaturedServices />

<!-- TODO: carousel -->

<section class="bg-white flex flex-col gap-8 py-12">
	<div class="relative text-center grid place-items-center">
		<div class="absolute w-full h-5 hidden sm:block gradient-gold top-[62%] md:top-[57%] -translate-y-1/2 z-10" />
		<div class="rounded-[100%] block max-sm-w-full sm:w-[250px] sm:h-[250px] md:w-[460px] md:h-[460px] bg-white col-start-1 z-10 row-start-1 col-span-1 row-span-1" />
		<img loading="lazy" class="block w-[278px] h-[278px] max-w-full md:w-[500px] md:h-[500px] col-start-1 row-start-1 col-span-1 row-span-1 z-20" src="//res.cloudinary.com/thomasedison/image/upload/f_auto,q_auto,ar_1,c_fill,w_500/home/award-wreath" alt="" />
		<h2 class="font-headline text-tee-dark-additional1 text-3xl md:text-5xl text-center mb-4 col-start-1 row-start-1 col-span-1 row-span-1 z-30">Award-Winning<br /> Company</h2>
	</div>
	<div class="w-full mx-auto p-8 md:px-16 flex flex-col lg:flex-row gap-20 lg:gap-48 justify-between">
		<div class="flex flex-col gap-4 mx-auto">
			<div class="flex w-full gap-4 max-h-32">
				{#each bhlAwards.items as award}
					{#if award.image}
						<div class="flex-auto max-h-full">
							<Picture image={award.image} />
							<!-- <img loading="lazy" src="//res.cloudinary.com/thomasedison/image/upload/f_auto,q_auto,h_128/home/happening-list-{year}" alt="Award: The Happening List {year}" height="128" /> -->
						</div>
					{/if}
				{/each}
			</div>
			<h3 class="text-tee-blue-default/40 pt-1 border-t-2 text-2xl md:text-3xl text-tee-dark-additional1 text-center font-headline border-t-black/40">{bhlAwards.items.length} Times on the Happening List</h3>
		</div>
		<div class="flex flex-col gap-4 mx-auto">
			<div class="flex w-full pr-4 max-h-32">
				{#each bobAwards.items as award}
					{#if award.image}
						<div class="flex-auto max-h-full -mr-8">
							<Picture image={award.image} />
							<!-- <img loading="lazy" src="//res.cloudinary.com/thomasedison/image/upload/f_auto,q_auto,h_128/home/one-of-the-best-{year}" alt="Award: One of the Best {year}" height="128" /> -->
						</div>
					{/if}
				{/each}
			</div>
			<h3 class="text-tee-blue-default/40 pt-1 border-t-2 text-2xl md:text-3xl text-tee-dark-additional1 text-center font-headline border-t-black/40">{bobAwards.items.length} Times One Of the Best</h3>
		</div>
	</div>
</section>
