<script lang="ts">
	import type { CloudinaryImage } from '$lib/functions/utils/buildImgSrc'
	import { imgSizes } from '$lib/functions/utils'

	type ContentfulImageType = {
		alt: string | null
		caption: string | null
		desktop: CloudinaryImage[]
		mobile: CloudinaryImage[]
	}
	type MediaType = 'desktop' | 'mobile'
	type ImageRulesType = Record<
		MediaType,
		{
			width: {
				min: number
				max: number
				imagePortion: number
				increment: number
			}
			height: {
				min: number
				max: number
				aspect: number
			}
		}
	>
	type ImgWidthType = { min: number; max: number; range: number[] }
	type ImgHeightType = { min: number; max: number; aspect: number }

	export let image: ContentfulImageType
	export let intent: 'illustration' | 'hero' | 'card' | 'default' = 'default'
	export let imgClasses: string = 'w-full h-full object-contain object-top'
	export let srcSets: ('desktop' | 'mobile')[] = ['desktop', 'mobile']

	const imageRules: ImageRulesType = {
		desktop: {
			width: {
				min: 1024,
				max: 1536,
				imagePortion: 1 / 10,
				increment: 200
			},
			height: {
				min: 631,
				max: 631,
				aspect: 1
			}
		},
		mobile: {
			width: {
				min: 330,
				max: 1023,
				imagePortion: 1 / 4,
				increment: 200
			},
			height: {
				min: 572,
				max: 572,
				aspect: 1
			}
		}
	}

	function buildImgProps(mediaType: MediaType) {
		const cloudinaryImage = image[mediaType]?.[0]
		if (cloudinaryImage) {
			const imageRule = imageRules[mediaType]
			const imgWidth: ImgWidthType = {
				min: Math.round(imageRule.width.min * imageRule.width.imagePortion),
				max: Math.round(imageRule.width.max * imageRule.width.imagePortion),
				range: []
			}
			const imgHeight: ImgHeightType = {
				min: Math.round(imageRule.height.min),
				max: Math.round(imageRule.height.max),
				aspect: imageRule.height.aspect
			}

			let width = imgWidth.min

			while (width < imgWidth.max) {
				if (imgWidth.max - imageRule.width.increment > width) imgWidth.range.push(width)
				width = width + imageRule.width.increment
			}
			imgWidth.range.push(imgWidth.max)

			return {
				src: imgSizes({ image: cloudinaryImage, range: [imgWidth.max], intent, aspect: imgHeight.aspect, isSrc: true }),
				srcset: imgSizes({ image: cloudinaryImage, range: imgWidth.range, intent, aspect: imgHeight.aspect }),
				width: imgWidth,
				height: imgHeight
			}
		}
	}

	const imageProps = {
		desktop: buildImgProps('desktop'),
		mobile: buildImgProps('mobile'),
		alt: image.alt
	}

	let specialImgProps: {[index:string]:string} = {}
	if (intent === 'hero') {
		specialImgProps.fetchpriority = 'high'
		specialImgProps.loading = 'eager'
	}else {
		specialImgProps.loading = 'lazy'
	}
</script>

{#if imageProps.desktop}
	<picture class="w-full overflow-hidden">
		{#if srcSets.includes('mobile') && imageProps.mobile}
			<source srcset={imageProps.mobile.srcset} sizes="100vw" />
		{/if}
		{#if srcSets.includes('desktop')}
			<source srcset={imageProps.desktop.srcset} sizes="100vw" />
		{/if}
		<img {...specialImgProps} src={imageProps.desktop.src} alt={imageProps.alt} sizes="(max-width: {imageProps.desktop.width.max}px) 100vw, {imageProps.desktop.width.max}px" width={imageProps.desktop.width.max} height={imageProps.desktop.height.max} class={imgClasses} />
	</picture>
{/if}
